import React from "react";
import { styled, keyframes } from "styled-components";
import {
  Scale,
  BookOpen,
  FileText,
  Gavel,
  CheckCircle,
  Building2,
  Briefcase,
  ScrollText,
  Users,
  School,
  University,
  GraduationCap,
} from "lucide-react";
import AnimatedBackground from "../components/AnimatedBackground";
import AnimatedSection from "../components/AnimatedSection";
import JoinTelegramSection from "../components/JoinTelegramSection";
import JoinProjectSection from "../components/JoinProjectSection";
import AnimatedBranchTree from "../components/AnimatedBranchTree";
import Reviews from "../components/Reviews";

const rotate = keyframes`
  from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
  to { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
`;

const scroll = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
`;

const ScrollingBanner = styled.div`
  background-color: #1e40af;
  color: white;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

const ScrollingText = styled.div`
  display: inline-block;
  animation: ${scroll} 30s linear infinite;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -20px;
  margin-left: -20px;
  animation: ${rotate} 30s linear infinite;
  color: white;
`;

export default function Jurisprudence() {
  let legalFields = [
    "ЮРИСПРУДЕНЦИЯ",
    "УГОЛОВНОЕ ПРАВО",
    "ГОСУДАРСТВЕННЫЕ ОРГАНЫ И КОММЕРЧЕСКИЕ СТРУКТУРЫ",
    "ГРАЖДАНСКОЕ ПРАВО",
    "ГОСУДАРСТВЕННЫЕ ОРГАНЫ И КОММЕРЧЕСКИЕ СТРУКТУРЫ",
  ];

  // let legalFields = [
  //   "ЮРИСПРУДЕНЦИЯ",
  //   "УГОЛОВНОЕ ПРАВО",
  //   "ГОСУДАРСТВЕННЫЕ ОРГАНЫ",
  //   "АДМИНИСТРАТИВНОЕ ПРАВО",
  //   "НЕКОММЕРЧЕСКИЕ СТРУКТУРЫ",
  //   "КОММЕРЧЕСКИЕ СТРУКТУРЫ",
  //   "ГРАЖДАНСКОЕ ПРАВО",
  // ];

  // if (window.innerWidth <= 450) {
  //   legalFields[2] = "ГОСУДАРСТВ. ОРГАНЫ";
  //   legalFields[3] = "АДМИНИСТР. ПРАВО";
  //   legalFields[4] = "НЕКОММЕРЧ. СТРУКТУРЫ";
  //   legalFields[5] = "КОММЕРЧ. СТРУКТУРЫ";
  // }

  // if (window.innerWidth <= 400) {
  //   legalFields[2] = "ГОСУДАР. ОРГАНЫ";
  //   legalFields[3] = "АДМИНИСТ. ПРАВО";
  //   legalFields[4] = "НЕКОММ. СТРУКТУРЫ";
  //   legalFields[5] = "КОММ. СТРУКТУРЫ";
  // }

  const icons = [
    Scale,
    BookOpen,
    FileText,
    Gavel,
    Building2,
    Briefcase,
    ScrollText,
    Users,
    CheckCircle,
  ];

  const reviews = [
    {
      name: "Дана Н.",
      role: "Выпускница программы",
      text: "Я невероятно благодарна за возможность поучаствовать в программе наставничества и особенно Казгериевой Эмме Викторовне. Это был действительно ценный опыт, который помог мне разобраться в мире юриспруденции. Благодаря работе с моим наставником, я поняла, что мир юриспруденции очень большой и многогранный. Я помогала в разборе судебного дела, классифицируя документы, и проводила много интервью с клиентами. Я очень рада, что сделала правильный выбор, поступив на свой факультет, и вдохновлена на дальнейшее развитие в выбранном направлении.",
      rating: 5,
    },
    {
      name: "Мурат К.",
      role: "Бакалавр, юрфак",
      text: "Хочу выразить огромную благодарность Эмме Викторовне! Мой наставник помогла мне увидеть юриспруденцию с совершенно новой стороны. Она показала мне важные моменты, помогла избавиться от предубеждений и просто безмерно вдохновила. Я получила практический опыт, участвуя в разборе судебного дела и проводя интервью с клиентами. Это помогло мне понять, что я нахожусь на правильном пути.",
      rating: 5,
    },
    {
      name: "Лиана М.",
      role: "Студентка, юрфак",
      text: "Я бы хотела пожелать всем, кто только предстоит проходить наставничество, чтобы они никогда не боялись пробовать себя в чем-то новом, идти через страх, если он есть, и не бояться неудач.   Опыт работы с моим наставником показал мне, что мир юриспруденции - это не только теория, но и практика, работа с реальными кейсами. Я получила огромное удовольствие от участия в проекте и рекомендую всем использовать такую возможность для своего развития.",
      rating: 5,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-25 font-sans">
      <section className="relative bg-blue-700 text-white py-60 md:py-56">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="/jury.avif"
            alt="Jurisprudence background"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <AnimatedSection>
          <div className="container mx-auto px-4 text-center relative z-10">
            <h1 className="text-2xl text-blue-100 font-bold mb-6">
              ЮРИСПРУДЕНЦИЯ
            </h1>
          </div>
          {icons.map((Icon, index) => (
            <IconWrapper
              key={index}
              style={{
                animationDelay: `${-index * (30 / icons.length)}s`,
                transform: `rotate(${
                  index * (360 / icons.length)
                }deg) translateX(150px) rotate(-${
                  index * (360 / icons.length)
                }deg)`,
              }}
            >
              <Icon className="text-blue-200 opacity-70 w-[40px] h-[40px]" />
            </IconWrapper>
          ))}
        </AnimatedSection>
      </section>

      <ScrollingBanner>
        <ScrollingText>
          {`${legalFields.join("   •   ")}   •   `.repeat(3)}
        </ScrollingText>
      </ScrollingBanner>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto mt-8">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            КОНЦЕПЦИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <Scale className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В рамках сферы «Юриспруденция» наставляемые имеют возможность
              получить уникальные прикладные навыки и знания посредством
              коммуникации с юристами в области гражданского и уголовного права,
              которые обладают большим профессиональным опытом в данной сфере.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <Gavel className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Наставничество будет осуществляться в виде личного взаимодействия
              специалиста и студента, в ходе которого наставник будет делиться с
              наставляемым своим профессиональным опытом, а также путем
              проведения лекций на актуальные темы, посещений судебных заседаний
              и решения практико-ориентированных кейсов.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <div className="flex justify-center items-center">
              <BookOpen className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            </div>
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              В ходе сессии проекта, наставляемые будут иметь возможность
              закрепить и углубить теоретические знания, изучить специфику
              деятельности представленных направлений юриспруденции, приобрести
              практические навыки работы в различных отраслях сферы, стать
              участником судебного процесса, выстроить понимание дальнейшего
              профессионального развития и обрести профессиональные
              коммуникационные сети для дальнейшего сотрудничества, выходящего
              за пределы проекта.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            ЭФФЕКТ ПРОГРАММЫ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Практические навыки, насмотренность юридических процессов, участие
              в судебном процессе, работа с юридическими документами.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Стажировка и возможность трудоустройства.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Написание научных статей совместно с наставником, либо под его
              руководством.
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <CheckCircle className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="md:mt-3 text-lg xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Рекомендации от наставника для дальнейшего трудоустройства.
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-6 md:py-16 px-4 max-w-6xl 2xl:max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            УРОВЕНЬ ОБРАЗОВАНИЯ
          </h2>
          <div className="flex items-start mb-6 xl:mb-10">
            <School className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              4 курс СПО
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <University className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              3-4 курс бакалавриата
            </p>
          </div>
          <div className="flex items-start mb-6 xl:mb-10">
            <GraduationCap className="w-10 h-10 xl:w-14 xl:h-14 mr-4 text-blue-800 flex-shrink-0 mt-1" />
            <p className="mt-1 md:mt-5 text-2xl xl:text-2xl 2xl:text-3xl leading-relaxed text-gray-700 text-justify">
              Магистратура
            </p>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="py-8 px-1 max-w-6xl mx-auto mb-4">
          <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-8 md:mb-16">
            НАПРАВЛЕНИЯ
          </h2>
          <AnimatedBranchTree
            mainField={legalFields[0]}
            subFields={legalFields.slice(1)}
            color={"darkblue"}
          />
        </section>
      </AnimatedSection>

      <section className="py-8 lg:my-12">
        <div className="container mx-auto px-4">
          <AnimatedSection>
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-blue-800 mb-16 md:mb-24">
              НАСТАВНИКИ ПРОШЛОГО ЗАПУСКА
            </h2>
          </AnimatedSection>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-x-16 lg:gap-y-20 justify-items-center">
            {[
              {
                name: "Коблев Руслан Пшимафович",
                sphere:
                  'Адвокат, член Адвокатской палаты г. Москвы. Управляющий партнер Адвокатского бюро "Коблев и партнеры"',
                image: "nastav_koblev.webp",
              },
              {
                name: "Казгериева Эмма Викторовна",
                sphere:
                  'Генеральный директор ООО "Центр правовой защиты". Председатель Кабардино-Балкарского регионального отделения Общероссийской общественной организации "Ассоциация юристов России"',
                image: "nastav_kazgerieva.webp",
              },
              {
                name: "Ворукова Мадина Хизировна",
                sphere:
                  'Адвокат, основатель юридической компании "Гранд Эгида"',
                image: "nastav_vorukova.webp",
              },
            ].map((mentor, index) => (
              <AnimatedSection key={mentor.name} className={"text-center"}>
                <img
                  src={mentor.image}
                  alt={mentor.name}
                  className="rounded-full mx-auto mb-4 w-40 h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 object-cover"
                />
                <h3 className="text-xl font-bold text-blue-800 mb-2">
                  {mentor.name}
                </h3>
                <p className="text-lg text-gray-600 mb-2 md:px-4">
                  {mentor.sphere}
                </p>
              </AnimatedSection>
            ))}
          </div>
        </div>
      </section>

      <Reviews
        reviews={reviews}
        textSize={"base"}
        textHeight={"auto"}
        titleColor={"blue"}
      />

      <JoinTelegramSection />

      <JoinProjectSection />
    </div>
  );
}
