import React from "react";
import AnimatedButton from "./AnimatedButton";
import AnimatedSection from "./AnimatedSection";

const JoinTelegramSection = () => {
  return (
    <section className="py-20 bg-[#B5DBFE] border border-1 border-[#B5DBFE]">
      <div className="container mx-auto px-4 text-center">
        <AnimatedSection>
          <h2 className="text-3xl font-bold text-blue-800 mb-8">
            Оставайтесь в курсе событий
          </h2>
          <p className="text-xl text-blue-600 mb-8">
            Присоединяйтесь к нашему Telegram каналу для получения последних
            новостей и обновлений
          </p>
          <AnimatedButton
            href="https://t.me/molpreddaily"
            variant="blue"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-semibold"
          >
            Наш Telegram
            <svg
              className="ml-3 w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22.05 1.577c-.393-.016-.784.08-1.117.235-.484.186-4.92 1.902-9.41 3.64-2.26.873-4.518 1.746-6.256 2.415-1.737.67-3.045 1.168-3.114 1.192-.46.16-1.082.362-1.61.984-.133.155-.267.354-.335.628s-.038.622.095.895c.265.547.714.773 1.244.976 1.76.564 3.58 1.102 5.087 1.608.556 1.96 1.09 3.927 1.618 5.89.174.394.553.54.944.544l-.002.02s.307.03.606-.042c.3-.07.677-.244 1.02-.565.377-.354 1.4-1.36 1.98-1.928l4.37 3.226.035.02s.484.34 1.192.388c.354.024.82-.044 1.22-.337.403-.294.67-.767.795-1.307.374-1.63 2.853-13.427 3.276-15.38l-.012.046c.296-1.1.187-2.108-.496-2.705-.342-.297-.736-.427-1.13-.444zm-.118 1.874c.027.025.025.025.002.027-.007-.002.03-.036-.002-.027zm-10.27 14.842l-.133 1.34 1.332-.554-1.2-.786z"
                clipRule="evenodd"
              />
            </svg>
          </AnimatedButton>
        </AnimatedSection>
      </div>
    </section>
  );
};

export default JoinTelegramSection;
