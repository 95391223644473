import { createSlice } from "@reduxjs/toolkit";
import env from "../env";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    readyToRender: false,
    isLoading: false,
    error: null,
    isLogged: undefined,
    users: [],
    user: {},
    dateTimeRestrictions: {},
  },
  reducers: {
    setReadyToRender(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload.users;
    },
    login(state, action) {
      const userData = action.payload.user;
      state.user = { ...userData };
      state.isLogged = true;

      const tokenExpirationDate = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 72
      );
      state.user.tokenExpirationDate = tokenExpirationDate.toISOString();

      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...userData,
          tokenExpirationDate: tokenExpirationDate.toISOString(),
        })
      );
    },

    logout(state) {
      state.user = {};
      state.isLogged = false;
      localStorage.removeItem("userData");
    },
    setDateTimeRestrictions(state, action) {
      state.dateTimeRestrictions = action.payload;
    },
  },
});

export const loadUserById = (sendRequest, userId) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + `/api/users/${userId}`,
        "GET",
        {
          "Content-Type": "application/json",
        }
      );
      dispatch(uiActions.login(responseData));
    } catch (error) {
      console.error("Error loading user:", error);
    }
  };
};

export const loadUsers = (sendRequest) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + "/api/users",
        "GET",
        {
          "Content-Type": "application/json",
        }
      );

      dispatch(uiActions.setUsers(responseData));
    } catch (error) {}
  };
};

export const loadDateTimeRestrictions = (sendRequest) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + "/api/dateTimeRestrictions",
        "GET",
        {
          "Content-Type": "application/json",
        }
      );
      dispatch(uiActions.setDateTimeRestrictions(responseData.restrictions));
    } catch (error) {
      console.error("Error loading datetime restrictions:", error);
    }
  };
};

export const updateDateTimeRestrictions = (sendRequest, sphere, date, time) => {
  return async (dispatch) => {
    try {
      const responseData = await sendRequest(
        env.BASE_URL + "/api/dateTimeRestrictions",
        "PATCH",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({ sphere, date, time })
      );
      dispatch(uiActions.setDateTimeRestrictions(responseData.restrictions));
    } catch (error) {
      console.error("Error updating datetime restrictions:", error);
    }
  };
};

export const uiActions = uiSlice.actions;

export default uiSlice;
